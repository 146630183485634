// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Mode } from '../assessment/assessment.model';
import { Client } from '../client/client.model';
import { Company } from '../company/company.model';
import { HasId } from '../core/entity/entity.service';
import { Dataset } from '../dataset/dataset.model';
import { QuestionnaireResponseMitigationIndicator } from '../questionnaire-response-mitigation-indicator/questionnaire-response-mitigation-indicator.model';
import { getOrderFromEnum } from '../shared/util';
import { _enforceSameEnumKeys } from '../shared/util.tooling';

export interface CompanyRecord extends HasId {
  name: string;
  client: Client;
  datasets: Dataset[];
  company: Company;

  properName?: string;
  description?: string;
  website?: string;
  verified?: string;

  mitigationDetails?: Record<
    keyof typeof Mode,
    {
      score: { label: keyof typeof MitigationScore; value: number };
      status: keyof typeof MitigationStatus;
      indicators: QuestionnaireResponseMitigationIndicator[];
      date: string;
    }
  >;
}

/**
 * Companies mitigation statuses. Order of statuses is important, which is declared in ascending order.
 */
export enum MitigationStatus {
  REQUESTED = 'Requested',

  IN_PROGRESS = 'In Progress',

  /**
   * When questionnaire response is submitted, show to user that the risk mitigation is in review.
   */
  SUBMITTED = 'In Review',

  NO_RESPONSE = 'No Response',
}

export const getMitigationStatusOrder = (status: keyof typeof MitigationStatus) =>
  Object.keys(MitigationStatus).indexOf(status);

/**
 * Company risk rating. Order of enum is important, which is declared in ascending order (low to high).
 */
/**
 * Company risk rating. Order of enum is important, which is declared in ascending order (low to high).
 */
export enum ERiskRating {
  LOW = 'LOW',
  MODERATE_LOW = 'MODERATE_LOW',
  MODERATE = 'MODERATE',
  MODERATE_HIGH = 'MODERATE_HIGH',
  HIGH = 'HIGH',
}

/**
 * @deprecated use {@link ERiskRating} instead.
 */
export enum RiskRating {
  LOW = 'Low',
  MODERATE_LOW = 'Moderate Low',
  MODERATE = 'Moderate',
  MODERATE_HIGH = 'Moderate High',
  HIGH = 'High',
}
_enforceSameEnumKeys(ERiskRating, RiskRating);

export const getRiskRatingOrder = (rating: keyof typeof RiskRating, nullFirst: boolean = true) =>
  rating != null ? getOrderFromEnum(RiskRating, rating) : nullFirst ? -1 : Object.keys(RiskRating).length;

export const riskRatingSort = (a: keyof typeof RiskRating, b: keyof typeof RiskRating) =>
  getRiskRatingOrder(a) - getRiskRatingOrder(b);

/**
 * Company risk mitigation score.
 */
export enum MitigationScore {
  BASIC = 'BASIC',
  DEVELOPING = 'DEVELOPING',
  ADVANCED = 'ADVANCED',
}

export const getAdjustedRiskRating = (
  industryRiskRating: keyof typeof RiskRating,
  mitigationScore: keyof typeof MitigationScore,
) => {
  let adjustedRiskRating: keyof typeof RiskRating = industryRiskRating;

  if (mitigationScore && mitigationScore === 'ADVANCED') {
    const riskRatings = Object.keys(RiskRating) as (keyof typeof RiskRating)[];

    const riskIndex = riskRatings.indexOf(industryRiskRating);

    if (riskIndex > 0) {
      adjustedRiskRating = riskRatings[riskIndex - 1];
    }
  }

  return adjustedRiskRating;
};
